import React from "react"
import Helmet from "react-helmet"
export default function Seo({ data }) {
  return (
    <Helmet title={data.title || "Funding North america"}>
      <meta
        name="description"
        content={data.decription || "Online Business Funding Made Easy"}
      ></meta>
    </Helmet>
  )
}
